var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2 pr-0 "},[_c('div',{staticClass:"d-flex justify-content-between align-items-start mb-2"},[_c('h4',{staticStyle:{"font-size":"20px","font-weight":"600"}},[_vm._v(" Add New Notice ")]),_c('u-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.left",value:(
      'From this section, you can post new notices to your students. You can share the notice to any specific batch or courses. The real-time notifications will be sent on your students mobile phone.'
    ),expression:"\n      'From this section, you can post new notices to your students. You can share the notice to any specific batch or courses. The real-time notifications will be sent on your students mobile phone.'\n    ",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer text-muted",attrs:{"icon":"fi-rr-interrogation","size":"24"}})],1),_c('validation-observer',{ref:"noticeRules"},[_c('b-form-group',{attrs:{"label":"Title","label-for":"basicInput"}},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"basicInput","state":errors.length > 0 ? false : null,"placeholder":"Enter Notice Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Course","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Course","label-for":"course","state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.courseList,"multiple":"","placeholder":"Select courses","reduce":function (val) { return val.value; }},model:{value:(_vm.course),callback:function ($$v) {_vm.course=$$v},expression:"course"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Batch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Batch","label-for":"batch","state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.batchList,"multiple":"","placeholder":"Select batch","reduce":function (val) { return val.value; }},model:{value:(_vm.batch),callback:function ($$v) {_vm.batch=$$v},expression:"batch"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Description")]),_c('validation-provider',{attrs:{"name":"Desc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"id":"textarea-default","placeholder":"Enter Notice Description","rows":"5"},model:{value:(_vm.desc),callback:function ($$v) {_vm.desc=$$v},expression:"desc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticClass:"mt-3",attrs:{"block":"","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_vm._v(" Submit ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }