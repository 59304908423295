<template>
	<div class=" ">
		<section class="notification-timeline">
	<b-row class="match-height" >
		<b-col xl="6" md="6" class="mb-md-0" style="border-right: 1px solid #efefef;"> 
			<notification-timeline></notification-timeline>
		</b-col>
		<b-col xl="6" md="6" class="mb-md-0 mb-2" >
			<notification-form  ></notification-form>
		</b-col>
	</b-row>
</section>
</div>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import NotificationForm from "./components/NoticesForm.vue";
import NotificationTimeline from "./components/NoticesTimeline.vue";

export default {
	components: {
		NotificationForm,
		NotificationTimeline,
		BRow,
		BCol
	},
};
</script>
<style lang="scss">
	.notification-timeline{
		    height: calc(100% - 47px);

	}
</style>
