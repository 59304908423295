<template>
  <div class="px-2 pr-0 ">
    <div class="d-flex justify-content-between align-items-start mb-2">
      <h4 class="" style="font-size: 20px; font-weight:600">
        Add New Notice
      </h4>
      <u-icon v-b-popover.hover.left="
        'From this section, you can post new notices to your students. You can share the notice to any specific batch or courses. The real-time notifications will be sent on your students mobile phone.'
      " icon="fi-rr-interrogation" size="24" class="cursor-pointer text-muted" />
    </div>
    <validation-observer ref="noticeRules">
      <b-form-group label="Title" label-for="basicInput">
        <validation-provider #default="{ errors }" name="title" rules="required">
          <b-form-input id="basicInput" v-model="title" :state="errors.length > 0 ? false : null"
            placeholder="Enter Notice Title" />

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-row>
        <b-col xl="6" md="6">
          <validation-provider #default="{ errors }" name="Course" rules="required">
            <b-form-group label="Course" label-for="course" :state="errors.length > 0 ? false : null">
              <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="courseList" v-model="course"
                multiple placeholder="Select courses" class="w-100" :reduce="(val) => val.value" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col xl="6" md="6">
          <validation-provider #default="{ errors }" name="Batch" rules="required">
            <b-form-group label="Batch" label-for="batch" :state="errors.length > 0 ? false : null">
              <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="batch" :options="batchList"
                multiple placeholder="Select batch" class="w-100" :reduce="(val) => val.value" />

              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <label for="textarea-default">Description</label>
      <validation-provider #default="{ errors }" name="Desc" rules="required">
        <b-form-textarea v-model="desc" :state="errors.length > 0 ? false : null" id="textarea-default"
          placeholder="Enter Notice Description" rows="5" />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </validation-observer>

    <b-button class="mt-3" block variant="primary" type="submit" @click.prevent="submitForm">
      Submit
    </b-button>
  </div>
</template>

<script>
import {
  BFormInput,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import courseList from "./courseList.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import firebase from "firebase/app";
import store from "@/store";

import { watch } from "@vue/composition-api";

export default {
  components: {
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    ToastificationContent
  },
  directives: {
    "b-popover": VBPopover,
  },

  // watch : {
  //   roleOptions : function () {
  //     console.log('roleOptions changed');
  //   }
  // },

  watch: {},

  data() {
    return {
      title: "",
      desc: "",
      course: [],
      batch: [],
      batchList: [{ label: "All", value: "All" }],
      courses: courseList,
      courseList: [],
    };
  },
  created() {
    // firebase
    //     .firestore()
    //     .collection("StudentID")
    //     .where("courseName", "==", 'MBA')
    //     .where("batch", "==", '2020')
    //     .get()
    //     .then((snap) => {console.log(snap.size)})
    for (let i = 2000; i <= 2030; i++) {
      let obj = { label: i.toString(), value: i };
      this.batchList.push(obj);
    }

    this.courses.map((obj) => {
      let ab = { label: obj, value: obj };
      this.courseList.push(ab);
    });
  },

  methods: {
    submitForm() {
      var code = store.state.mainUserCodes;
      console.log(code);
      var uid = firebase.auth().currentUser.uid
      return new Promise((resolve, reject) => {
        this.$refs.noticeRules.validate().then((success) => {
          console.log(success);
          if (success) {
            firebase
              .firestore()
              .collection("Notices")
              .add({
                title: this.title,
                desc: this.desc,
                batch: this.batch,
                course: this.course,
                TPOid: uid,
                StudentCode: code.StudentCode,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then(() => {
                console.log("done");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'New Notice Posted',
                    icon: 'fi-rr-exclamation',
                    text: '👋 Your new Notice posted successfully.',
                    variant: 'success',
                  },
                })
               
                this.title = ''
                this.desc = ''
                this.batch = []
                this.course = []
              });
            // this.cloudfun(code.StudentCode)

            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    cloudfun(StudentCode) {
      let newVal = {};
      newVal.timestamp = new Date();
      let courses = this.course;
      let batches = this.batch;
      let allcourse = courses.includes("All Courses");
      let allbatch = batches.includes("All");

      if (!allcourse && !allbatch) {
        console.log("no course no batch");
        courses.map((course) => {
          let notiPromises = [];
          let writePromise = [];
          batches.map(async (batch) => {
            console.log(course);
            console.log(batch);
            await firebase
              .firestore()
              .collection("StudentID")
              .where("courseName", "==", course)
              .where("batch", "==", batch)
              .where("StudentCode", "==", StudentCode)
              .get()
              .then((snap) => {
                console.log(snap.size);
                if (snap.size > 0) {
                  snap.forEach((doc) => {
                    console.log(doc.data().FullName);

                    let tokens =
                      typeof doc.data().DeviceToken != "undefined"
                        ? doc.data().DeviceToken
                        : [];
                    console.log(tokens);
                    const payload = {
                      notification: {
                        title: " Notice",
                        body: "Notice Posted",
                        click_action: "FLUTTER_NOTIFICATION_CLICK",

                        // required only for onResume or onLaunch callbacks
                      },
                      data: {
                        click_action: "FLUTTER_NOTIFICATION_CLICK",
                        noticeid: itemId,
                        //   screen:'chat',
                        //   chatid:chatid,
                        //   senderid:senderId,
                        sender: "tpo",
                      },
                    };
                    // notiPromises.push(fcm.sendToDevice(tokens, payload));

                    let obj = {
                      category: "notice",
                      id: itemId,
                      //   logo:logo,
                      notifytext: `New notice posted`,
                      timestamp: newVal.timestamp,
                    };

                    writePromise.push(
                      firebase
                        .firestore()
                        .collection("StudentID")
                        .doc(doc.id)
                        .collection("Activity")
                        .doc(doc.id)
                        .set(
                          {
                            notificationService: firebase.firestore.FieldValue.arrayUnion(
                              obj
                            ),
                            isread: false,
                          },
                          { merge: true }
                        )
                    );
                  });
                }
                return null;
              })
              .catch((err) => console.log(err));
          });
          // Promise.all(notiPromises).catch(err =>console.log(err))
          Promise.all(writePromise).catch((err) => console.log(err));
        });
      } else if (allcourse && !allbatch) {
        console.log(" course no batch");

        let notiPromises = [];
        let writePromise = [];
        batches.map(async (batch) => {
          console.log(batch);
          await firebase
            .firestore()
            .collection("StudentID")
            .where("batch", "==", batch)
            .where("StudentCode", "==", StudentCode)
            .get()
            .then((snap) => {
              console.log(snap.size);
              if (snap.size > 0) {
                snap.forEach((doc) => {
                  console.log(doc.data().FullName);

                  let tokens =
                    typeof doc.data().DeviceToken != "undefined"
                      ? doc.data().DeviceToken
                      : [];
                  console.log(tokens);
                  const payload = {
                    notification: {
                      title: " Notice",
                      body: "Notice Posted",
                      click_action: "FLUTTER_NOTIFICATION_CLICK",

                      // required only for onResume or onLaunch callbacks
                    },
                    data: {
                      click_action: "FLUTTER_NOTIFICATION_CLICK",
                      //   screen:'chat',
                      //   chatid:chatid,
                      //   senderid:senderId,
                      sender: "tpo",
                    },
                  };
                  // notiPromises.push(fcm.sendToDevice(tokens, payload));

                  let obj = {
                    category: "notice",
                    // id: itemId,
                    //   logo:logo,
                    notifytext: `New notice posted`,
                    timestamp: newVal.timestamp,
                  };

                  writePromise.push(
                    firebase
                      .firestore()
                      .collection("StudentID")
                      .doc(doc.id)
                      .collection("Activity")
                      .doc(doc.id)
                      .set(
                        {
                          notificationService: firebase.firestore.FieldValue.arrayUnion(
                            obj
                          ),
                          isread: false,
                        },
                        { merge: true }
                      )
                  );
                });
              }
              return null;
            })
            .catch((err) => console.log(err));
        });
        // Promise.all(notiPromises).catch(err =>console.log(err))
        Promise.all(writePromise).catch((err) => console.log(err));
      } else if (!allcourse && allbatch) {
        console.log("no course   batch");

        courses.map(async (course) => {
          let notiPromises = [];
          let writePromise = [];
          console.log(course);
          await firebase
            .firestore()
            .collection("StudentID")
            .where("courseName", "==", course)
            .where("StudentCode", "==", StudentCode)
            .get()
            .then((snap) => {
              console.log(snap.size);
              if (snap.size > 0) {
                snap.forEach((doc) => {
                  console.log(doc.data().FullName);

                  let tokens =
                    typeof doc.data().DeviceToken != "undefined"
                      ? doc.data().DeviceToken
                      : [];
                  console.log(tokens);
                  const payload = {
                    notification: {
                      title: " Notice",
                      body: "Notice Posted",
                      click_action: "FLUTTER_NOTIFICATION_CLICK",

                      // required only for onResume or onLaunch callbacks
                    },
                    data: {
                      click_action: "FLUTTER_NOTIFICATION_CLICK",
                      //   screen:'chat',
                      //   chatid:chatid,
                      //   senderid:senderId,
                      sender: "tpo",
                    },
                  };
                  // notiPromises.push(fcm.sendToDevice(tokens, payload));

                  let obj = {
                    category: "notice",
                    // id: itemId,
                    //   logo:logo,
                    notifytext: `New notice posted`,
                    timestamp: newVal.timestamp,
                  };

                  writePromise.push(
                    firebase
                      .firestore()
                      .collection("StudentID")
                      .doc(doc.id)
                      .collection("Activity")
                      .doc(doc.id)
                      .set(
                        {
                          notificationService: firebase.firestore.FieldValue.arrayUnion(
                            obj
                          ),
                          isread: false,
                        },
                        { merge: true }
                      )
                  );
                });
              }
              return null;
            })
            .catch((err) => console.log(err));
          // Promise.all(notiPromises).catch(err =>console.log(err))
          Promise.all(writePromise).catch((err) => console.log(err));
        });
      }

      if (allcourse && allbatch) {
        console.log(" course  batch");

        let notiPromises = [];
        let writePromise = [];
        firebase
          .firestore()
          .collection("StudentID")
          .where("StudentCode", "==", StudentCode)
          .get()
          .then((snap) => {
            console.log(snap.size);
            if (snap.size > 0) {
              snap.forEach((doc) => {
                console.log(doc.data().FullName);

                // let   tokens = typeof doc.data().DeviceToken !='undefined' ?  doc.data().DeviceToken:[] ;
                console.log(tokens);
                const payload = {
                  notification: {
                    title: " Notice",
                    body: "Notice Posted",
                    click_action: "FLUTTER_NOTIFICATION_CLICK",

                    // required only for onResume or onLaunch callbacks
                  },
                  data: {
                    click_action: "FLUTTER_NOTIFICATION_CLICK",
                    //   screen:'chat',
                    //   chatid:chatid,
                    //   senderid:senderId,
                    sender: "tpo",
                  },
                };
                notiPromises.push(fcm.sendToDevice(tokens, payload));

                let obj = {
                  category: "notice",
                  // id: itemId,
                  //   logo:logo,
                  notifytext: `New notice posted`,
                  timestamp: newVal.timestamp,
                };

                writePromise.push(
                  firebase
                    .firestore()
                    .collection("StudentID")
                    .doc(doc.id)
                    .collection("Activity")
                    .doc(doc.id)
                    .set(
                      {
                        notificationService: firebase.firestore.FieldValue.arrayUnion(
                          obj
                        ),
                        isread: false,
                      },
                      { merge: true }
                    )
                );
              });
            }
            return null;
          })
          .catch((err) => console.log(err));
        // Promise.all(notiPromises).catch(err =>console.log(err))
        Promise.all(writePromise).catch((err) => console.log(err));
      }
      //empty form and show notification of successfully posted
      this.successfullyPosted()
      return null;
    },
  },
  successfullyPosted() {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Notification Posted',
        icon: 'BellIcon',
        text: '👋 Notification to your selected students have been posted successfully.',
        variant: 'success',
      },
    })
    this.title = ""
    this.desc = ""
    this.course = []
    this.batch = []


  }
};
</script>
<style tlang="scss">
.v-select .vs__dropdown-toggle {
  padding: 6px 2px;
  /*0.59px 0 4px 0*/
}

.v-select .vs__dropdown-toggle ::placeholder {
  color: #043c57;
  opacity: 0.4;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
