<template>
	<div class="pr-1 ">
		<!-- <b-card> -->
		<div class="d-flex justify-content-between align-items-start mb-2">
			<h4 class="" style="font-size: 20px; font-weight:600">
				Notices Timeline
			</h4>
			<u-icon v-b-popover.hover.left="
				'This section shows all your posted notices to the students and real-time notifications had already been delivered to the students.'
			" icon="fi-rr-interrogation" size="24" class="cursor-pointer text-muted" />
		</div>

		<vue-perfect-scrollbar ref="refChatLogPS" class="notification-timeline-scroller"
			:settings="perfectScrollbarSettings" style=" overflow-x:hidden;">
			<div v-if="noticesArray.length == 0"
				class="w-100 d-flex flex-column justify-content-center text-center align-items-center align-middle"
				style="height:inherit;">
				<u-icon icon="fi-rr-megaphone" class="text-secondary" size="64" style="opacity: 0.2; color:#043c57" />
				<span style="opacity: 0.2;color:#043c57; font-size: 20px;">No Notifications Posted</span>
			</div>
			<section class="mr-2">
				<!-- class="user-chats scroll-area" -->

				<app-timeline>
					<!-- ONLINE STORE -->
					<app-timeline-item variant="primary" class="pb-5" v-for="item in noticesArray" :key="item.id">
						<div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
							<h4 class="text-capitalize">{{ item.title }}</h4>
							<small v-if="item.timestamp">
								<timeago :datetime="item.timestamp.toDate()"></timeago>
								<!-- {{ item.timestamp.toDate() }} -->
							</small>
						</div>
						<div class="d-flex">
							<p style="line-height: 25px; overflow: auto; overflow-wrap:anywhere">{{ item.desc }}</p>
						</div>
						<div class="d-flex flex-sm-row flex-column justify-content-between align-items-start mt-2">
							<!-- 1st Col -->
							<div class="mb-1 mb-sm-0">
								<span class=" mb-25" style="font-size:12px">Batches</span>

								<div>
									<!-- {{ item.batch }} -->
									<b-badge v-for="(batch, index) in item.batch" :key="index" pill variant="light-dark"
										class="text-capitalize font-weight-bold mr-25 mt-50">
										{{ batch }}
									</b-badge>
								</div>
							</div>

							<!-- 3rd Col -->
							<div>
								<span class=" mb-25 d-block" style="font-size:12px">Courses</span>
								<!-- <span>{{ item.course }}</span> -->
								<div class="justify-content-end align-items-end">
									<b-badge v-for="(course, index) in item.course" :key="index" pill
										variant="light-dark" class="text-capitalize font-weight-bold ml-25 mt-50">
										{{ course }}
									</b-badge>
								</div>
							</div>
						</div>
					</app-timeline-item>
				</app-timeline>
			</section>
		</vue-perfect-scrollbar>
		<!-- </b-card> -->
	</div>
</template>

<script>
import {
	BImg,
	BAvatar,
	BMedia,
	BButton,
	BCollapse,
	VBToggle,
	BListGroup,
	BListGroupItem,
	BAvatarGroup,
	BBadge,
	VBTooltip, BCard,
	VBPopover,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import firebase from "firebase/app";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
	components: {
		AppTimeline,
		AppTimelineItem,
		BImg,
		BMedia,
		BAvatar,
		BButton,
		BCollapse,
		BListGroup,
		BListGroupItem,
		BAvatarGroup,
		BBadge,
		BCard,
		// 3rd Party
		VuePerfectScrollbar,
	},
	directives: {
		"b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple, "b-popover": VBPopover,
	},
	data() {
		return {
			noticesArray: [],
			perfectScrollbarSettings: {
				maxScrollbarLength: 150,
			},
		};
	},
	methods: {
		getAllNotices(userId) {
			var uid = firebase.auth().currentUser.uid
			firebase
				.firestore()
				.collection("Notices")
				.orderBy("timestamp", "desc")
				.where("TPOid", "==", uid)
				.onSnapshot((querySnapshot) => {
					this.noticesArray = [];

					querySnapshot.docs.forEach((doc) => {
						// if (change.type === "added") {
						// console.log(notices.length);
						// notices.forEach((notify) => {
						var obj = doc.data();
						obj.id = doc.id;
						this.noticesArray.push(obj);
						// });
						// }
					});
				});
		},
	},
	created() {
		if (this.$store.state.mainUserCodes) {
			this.getAllNotices(this.$store.state.mainUserCodes.user_id);
		}

		//changing when store value changed
		this.$store.watch(
			(state) => {
				return this.$store.state.mainUserCodes;
			},
			(newVal, oldVal) => {
				this.getAllNotices(this.$store.state.mainUserCodes.user_id);
			},
			{ deep: true }
		);
	},
};
</script>
<style>
.notification-timeline-scroller {
	height: calc(98vh - (3.35rem + 4.45rem + 4.45rem));
}

@media (max-width: 768px) {
	.notification-timeline-scroller {
		height: auto
	}
}

@media (max-width: 991px) {
	.notification-timeline-scroller {
		height: auto
	}
}
</style>
