export default[
    "All Courses",
    "B.A - L.L.B. (Hons.)",
    "B.A. LL. B. (Hons.)",
    "B.Arch",
    "B.B.A. LL. B. (Hons.)",
    "B.B.S",
    "B.Com",
    "B.Com (Hons.)",
    "B.Com (Year)",
    "B.D.S",
    "B.Des",
    "B.E.",
    "B.E. (Hons.)",
    "B.E. (Hons.) + B.E. (Hons.)",
    "B.E. (Hons.) + M.Sc. (Hons.)",
    "B.Ed",
    "B.Ed.(Year)",
    "B.F.A",
    "B.F.Tech",
    "B.H.M.S.",
    "B.Pharm",
    "B.Pharm (Hons.)",
    "B.Plan",
    "B.S. &amp; M.S. (Dual Degree)",
    "B.Sc",
    "B.Sc (4 year)",
    "B.Sc (Biotechnology)",
    "B.Sc (Hons)",
    "B.Sc (Year)",
    "B.Tech",
    "B.Tech (Hons.)",
    "B.V.EBA",
    "BA (Hons.)",
    "BA LLB",
    "BAC + 5",
    "Baccalaurete",
    "BAF",
    "BBA",
    "BBA(Hons)",
    "BBALLB",
    "BBE",
    "BBM",
    "BCA",
    "BCA (Year",
    "BHM",
    "BJMC",
    "BMMBMS (3 Year)",
    "BMS (4 Year)",
    "BSW",
    "BVA",
    "CA",
    "Cert",
    "CLASS X",
    "CLASS XII",
    "CS",
    "D.Ed",
    "D.Pharm",
    "D.Pharm (Year)",
    "Dip",
    "Dip (3 Years)",
    "Dual Degree - M.Tech.",
    "Dual Degree - MBA.",
    "L.L.M",
    "Licence (BAC + 3)",
    "LLB",
    "M.A (1 Year)",
    "M.Arch",
    "M.C.M.",
    "M.Com",
    "M.Des.",
    "M.E.",
    "M.E. (Integrated)",
    "M.F.A.",
    "M.H.M",
    "M.H.R.D",
    "M.Pharm",
    "M.Sc (Year)",
    "M.Sc.",
    "M.Sc. (Hons.)",
    "M.Sc. (Hons.) + B.E. (Hons.)",
    "M.Sc. (Hons.) + M.Sc. (Hons.)",
    "M.Sc. (Integrated)",
    "M.Sc. (Tech.)",
    "M.Sc. (Tech., 3 years)",
    "M.Sc. - Ph.D.",
    "M.Tech (Integrated)",
    "M.Tech.",
    "MA",
    "Masters",
    "MBA",
    "MBA (Quarters)",
    "MBA (Semester)",
    "MBA (Trimester)",
    "MBA + PGDM",
    "MBA+PGPM",
    "MBAEX",
    "MBBS",
    "MCA",
    "MCP",
    "MFA",
    "MHA",
    "MIB",
    "Minor",
    "MJMC",
    "MLS",
    "MMS",
    "MMST",
    "MS",
    "MSW",
    "MURP",
    "P.G.C.",
    "P.G.D (1 Year)",
    "P.G.D.A",
    "P.G.D.C.A",
    "P.G.D.M (1 Year)",
    "P.G.D.M (2 Year)",
    "P.G.D.M (3 Trimester)",
    "P.G.D.M (6 Trimester)",
    "P.G.D.M (BKFS)(6 Trimester)",
    "P.G.D.M (HR)(6 Trimester)",
    "P.G.D.M (MSM)",
    "P.G.P.M (1 Year)",
    "P.G.P.M. (2 Year)",
    "PGDBA",
    "PGP",
    "PGP (3 Trimester)",
    "PGPEX_VLM",
    "PGPX",
    "Ph.D.",
    "Pharm.D",
    "Pharm.D (PB)",
    "Prodegree",
]